import {systemApi} from "@/api/system";
import {fileApi} from "@/api/file";
import {reactive} from "vue";

export const packageBaseUrl = "/admin"
//网站设置
const config = reactive({
    name: "信息管理空间",
    logoUrl: "",
    iconUrl: "",
    qiqiuyunPrivate: false,
})
export const getWebSetting = async () => {
    const res = await systemApi.getSetting()
    if (res.name) {
        config.name = res.name
    }
    if (res.logoUuid) {
        config.logoUrl = fileApi.getFile(res.logoUuid);
    }
    if (res.iconUuid) {
        config.iconUrl = fileApi.getFile(res.iconUuid);
    }
    if (res.cloudPlayHost) {
        localStorage.setItem('cloudPlayHost', getUrlHost(res.cloudPlayHost))
    }

    config.qiqiuyunPrivate = res.qiqiuyunPrivate

    refreshWebIconAndTitle()
}

export const refreshWebIconAndTitle = () => {
    document.querySelector('link[rel="icon"]').href = config.iconUrl
}

export const refresh = async (router) => {
    await getWebSetting();
    refreshWebIconAndTitle(router)
}

const getUrlHost = (url) => {
    const reg = /http[s]?:\/\/([^\/]*)/i;
    const result = url.match(reg);
    return result && result[1];
}

export default config;

