import {createRouter, createWebHistory} from 'vue-router'
import BasicLayout from "@/layouts/BasicLayout";
import BlankLayout from "@/layouts/BlankLayout";
import config from "@/config";
import {useAuthStore} from "@shared/store/useAuthStore";

const routes = [
    {
        path: '/',
        name: 'index',
        meta: {title: 'Home'},
        component: BasicLayout,
        redirect: '/welcome',
        children: [
            {
                path: '/welcome',
                name: 'welcome',
                meta: {title: '欢迎'},
                component: () => import('../views/WelcomePage.vue'),
            },
            {
                path: '/custom',
                name: 'custom',
                meta: {title: '自定义'},
                component: () => import('../views/WelcomePage.vue'),
            },
            {
                path: '/student/record',
                name: 'studentRecord',
                meta: {title: '一人一档'},
                component: () => import('../views/student/StudentManagePage.vue'),
            },
            {
                path: '/class/record',
                name: 'classRecord',
                meta: {title: '一期一档'},
                component: () => import('../views/train/TrainManage.vue'),
            },
            {
                path: '/student',
                name: 'student',
                meta: {title: '学员管理', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: '/student/student',
                children: [
                    {
                        path: '/student/student',
                        name: 'studentManage',
                        meta: {title: '学员管理'},
                        component: () => import('../views/student/StudentManagePage.vue'),
                    },
                ]
            },
            {
                path: '/train',
                name: 'train', meta: {title: '培训管理', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: '/train/train',
                children: [
                    {
                        path: '/train/train',
                        name: 'trainManage',
                        meta: {title: '培训管理'},
                        component: () => import('../views/train/TrainManage.vue'),
                    }
                ]

            },
            {
                path: '/user',
                name: 'user',
                meta: {title: '用户管理', icon: 'UserOutlined'},
                component: BlankLayout,
                redirect: '/user/index',
                children: [
                    {
                        path: '/user/index',
                        name: 'user_index',
                        meta: {title: '用户管理'},
                        component: () => import('../views/UserPage.vue'),
                    },
                    // {
                    //     path: '/user/role',
                    //     name: 'user_role',
                    //     meta: {title: '角色'},
                    //     component: () => import('../views/UserRolePage.vue'),
                    // },
                ]
            },
            {
                path: '/setting',
                name: 'setting',
                meta: {title: '系统设置', icon: 'SettingOutlined'},
                component: BlankLayout,
                redirect: '/setting/index',
                children: [
                    {
                        path: '/setting/index',
                        name: 'setting_index',
                        meta: {title: '基本信息'},
                        component: () => import('../views/setting/setting.vue'),
                    },
                    {
                        path: '/setting/nav',
                        name: 'nav_index',
                        meta: {title: '导航管理'},
                        component: () => import('../views/navigation/NavigationSetting.vue'),
                    },
                    {
                        path: '/setting/shortcut',
                        name: 'shortCurtSetting',
                        meta: {title: '快捷方式'},
                        component: () => import('../views/shortcut/shortcutSetting.vue'),
                    },
                ]
            },

        ]
    },
    {
        path: '/table/index',
        name: 'studentTable',
        meta: {title: '一人一档'},
        component: () => import('../views/student/common/TableRecord.vue'),
    },
    {
        path: '/:corpCode/login',
        name: 'login',
        meta: {title: '登录', requiredAuth: false},
        component: () => import('../views/LoginPage.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title + " | " + config.name;
    }

    if (to.meta.requiredAuth === undefined || to.meta.requiredAuth === true) {
        const store = useAuthStore();
        if (!store.isLogin) {
            next({name: "login", params: {corpCode: "base"}});
        }
    }

    next();
});

export default router
