import '@ant-design-vue/pro-layout/dist/style.less';

import ProLayout, {PageContainer} from '@ant-design-vue/pro-layout';
import {
    Button,
    Card,
    Form,
    Input,
    InputNumber,
    Space,
    Typography,
    Table,
    Divider,
    Dropdown,
    Menu,
    Drawer,
    Tabs,
    Descriptions,
    Row,
    Col,
    Radio,
    DatePicker,
    Checkbox,
    Select,
    Modal,
    Upload,
    Avatar,
    Spin, Popconfirm, Image,
    Tree,
    Switch,
    Empty,
    Tooltip,
    Pagination
} from 'ant-design-vue';
import icons from '@/icons';
import {RadioGroup} from "ant-design-vue/es";

export function bootAntDesignVue(app) {
    app
        .use(ProLayout)
        .use(PageContainer)
        .use(icons)
    ;

    app
        .use(Button)
        .use(Card)
        .use(Form)
        .use(Input)
        .use(InputNumber)
        .use(Space)
        .use(Typography)
        .use(Empty)
        .use(Table)
        .use(Divider)
        .use(Dropdown)
        .use(Menu)
        .use(Drawer)
        .use(Tabs)
        .use(Descriptions)
        .use(Row)
        .use(Col)
        .use(DatePicker)
        .use(Select)
        .use(Checkbox)
        .use(Modal)
        .use(Spin)
        .use(Popconfirm)
        .use(Image)
        .use(Upload)
        .use(Tree)
        .use(Switch)
        .use(Tooltip)
        .use(Avatar)
        .use(Pagination)
    ;

    app.component(RadioGroup.name, RadioGroup);
    app.component(Radio.name, Radio);
}
