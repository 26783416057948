<template>
  <pro-layout
      :locale="locale"
      v-model:openKeys="state.openKeys"
      v-model:collapsed="state.collapsed"
      v-model:selectedKeys="state.selectedKeys"
      :menu-data="menuData.data"
      v-bind="layoutConf"

  >
    <template #rightContentRender>
      <div style="display: flex;align-items: center;padding-right: 10px; ">
        <skin-filled @click="changeLayout"
                     :style="{color: layoutConf.layout=='mix' ? '#fff':'#000','margin-right': '10px','font-size': '20px'}"/>
        <span v-if="store.user" :style="{color: layoutConf.layout=='mix' ? '#fff':'#000'}">您好，{{
            store.user.username
          }}</span>
        <a-button type="link" @click="onLogout">退出</a-button>
      </div>
    </template>

    <template #menuItemRender="{ item, icon }">
      <a-menu-item
          v-if="item.status"
          :key="item.path"
          :disabled="item.meta?.disabled"
          :danger="item.meta?.danger"
          :icon="icon"
          @click="topMenuClick(item)"
      >
        <span class="ant-pro-menu-item">
            <span class="ant-pro-menu-item-title">{{ item.meta.title }}</span>
        </span>
      </a-menu-item>
    </template>

    <router-view/>
  </pro-layout>
</template>

<script setup>
import {onMounted, reactive, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {systemApi} from "@/api/system";
import {navigationApi} from "@/api/navigation";
import {useAuthStore} from "@shared/store/useAuthStore";
import config from "@/config";

const store = useAuthStore();
const locale = (i18n) => i18n;
const router = useRouter();
// let {menuData} = getMenuData(clearMenuItem(router.getRoutes()));
let menuData = reactive({
  data: []
})
onMounted(() => {
  getNavList()
})

const getNavList = async () => {
  const res = await navigationApi.list();
  const layout = await systemApi.getSetting();
  layoutConf.value.layout = layout.layoutType === 'LEFT' ? 'mix' : 'top'
  if (res) {
    setKeyMenuData(res)
    menuData.data = res
  } else {
    menuData.data = [{
      "id": 7,
      "parentId": null,
      "weight": 4,
      "name": "系统设置",
      "seq": 4,
      "url": "/setting",
      "path": "/setting",
      "meta": {"title": "系统设置"},
      "children": [{
        "id": 9,
        "parentId": 7,
        "weight": 0,
        "name": "导航管理",
        "seq": 0,
        "url": "/setting/nav",
        "path": "/setting/nav",
        "meta": {"title": "导航管理"}
      }, {
        "name": "基本信息",
        "url": "/setting/index",
        "path": "/setting/index",
        "meta": {"title": "基本信息"}
      }],
    }]
  }
}
const setKeyMenuData = (data) => {
  for (const item of data) {
    item['path'] = item['url']
    item['meta'] = {'title': item.name}
    if (item.children) {
      setKeyMenuData(item.children)
    }
  }
}
const navClick = (e) => {
  console.log(e)
  router.push({path: e.key})
}
const topMenuClick = (e) => {
  console.log(state.selectedKeys)
  if (markUrl((e.url))) {
    window.open(e.url)
    return
  }
  e.newWin ? window.open(window.origin + e.url) : router.push({path: e.path})
}
const markUrl = (url) => {
  if (url.includes("http://") || url.includes("https://")) {
    return true
  } else {
    return false
  }
}
const state = reactive({
  collapsed: false, // default value
  openKeys: [],
  selectedKeys: [],
});

const layoutConf = ref({
  title: config.name,
  navTheme: 'dark',
  layout: 'top',
  splitMenus: false,
  fixSiderbar: true,
  logo: config.logoUrl ? config.logoUrl : require('@/assets/logo.png'),
});
const changeLayout = async () => {
  layoutConf.value.layout = layoutConf.value.layout == 'mix' ? 'top' : 'mix'
  // layoutConf.value.navTheme = layoutConf.value.navTheme == 'light' ? 'dark' : 'light'
  const res = await systemApi.getSetting();
  let setting = Object.assign({}, res)
  setting.layoutType = layoutConf.value.layout == 'mix' ? 'LEFT' : 'TOP'
  await systemApi.updateSetting(setting);
  await getNavList()

}


watch(router.currentRoute, () => {
  const matched = router.currentRoute.value.matched.concat()

  state.selectedKeys = matched.filter(r => r.name !== 'index').map(r => r.path)
  state.openKeys = matched.filter(r => r.path !== router.currentRoute.value.path).map(r => r.path)
}, {
  immediate: true,
})

const onLogout = () => {
  store.logout();
  router.push({name: "login", params: {corpCode: localStorage.getItem("corpCode")}});
}

</script>
<style>
.ant-pro-top-menu {
  height: 104px !important;
  background: #fff !important;
}

.ant-pro-top-nav-header-logo {
  padding-left: 20px;
}

.ant-pro-top-nav-header-logo h1 {
  color: #000 !important;
}

.ant-pro-top-nav-header-main div:nth-child(3) {
  margin-left: auto;
}

.ant-pro-top-nav-header-main {
  padding-left: 0 !important;
}


.ant-pro-top-nav-header-main > .ant-pro-top-nav-header-menu {
  position: absolute;
  background: #001529;
  width: 100%;
  bottom: 0;
  padding-left: 140px;
}
</style>
